<template>

  <div id="app">
    
    <div id="nav"> <router-link to="/"><img alt="Vue logo" style="width:120px;margin-top:-8px;margin-left:20px" src="./assets/suds.svg"></router-link></div>
    
            <div id="nav" v-if="hasProduct()" class="row fixed-top" style="text-align:center"><router-link to="/"><img alt="Vue logo" style="width:120px;margin-top:-8px;margin-left:20px" src="./assets/suds.svg"></router-link>
              
     <!-- <router-link class=" col" to="/">Home </router-link> 
      <router-link class=" col" to="/about"> About</router-link>
            <router-link class=" col" to="/about">Products </router-link> 
      <router-link class=" col" to="/about"> Contact Us</router-link>-->
      <div class="col"></div>
      <div class="col-auto" style="text-align:right">
      <btn btnColor="mt-1 btn btn-small btn-info btn-popup"
         :cartIcon="true"
         @click.native="showPopupCart()">
         Cart
        <span class="btn-circle" v-if="hasProduct()">
           {{ getProductsInCart.length }}
        </span>
      </btn></div>
      <popupcart class="cart" v-if="getPopupCart"/><maskBg v-if="getPopupCart" @click.native="showPopupCart()"/>
    </div>
    <div class="row p-2" style="background-color:red;color:white;font-size:calc(16px + 1.5vw)"><div class="col">We’re back with an all new MEGA SUDSPENSER<div style="font-size:calc(13px + 1vw)">By popular demand we have started limited
production of a cost effective and reliable liquid laundry detergent dispensing system for residential
use and small commercial laundry facilities.</div></div></div>
    <router-view/>


  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import btn from './components/Btn';
import popupcart from './components/Popupcart';
import maskBg from './components/Mask';
//import listOfProducts from './components/ListOfProducts';

export default {
  components: {
    //listOfProducts,
    btn,
    popupcart,
    maskBg,
  },
  methods: {
    ...mapActions([
      'showOrHiddenPopupCart',
    ]),
    hasProduct() {
      return this.getProductsInCart.length > 0;
    },
    showPopupCart() {
      this.showOrHiddenPopupCart();
    },
  },
  computed: {
    ...mapGetters([
      'getAllProducts',
      'getProductsInCart',
      'getPopupCart',
    ]),
  },
};
</script>

<style>
body{
  background-color:#e6e6e6;
  margin:0px;
}
#app {
   font-family:'Pragati Narrow', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow:hidden;
}

#nav {
  padding: 10px;
  background-image: linear-gradient(to right, #2e3192,#0059a9,#007cc2,#1b1464); 
  color:white !important;
  text-decoration:none !important;
  border-bottom:3px solid #42b983;
  font-size:25px;
  height:70px;
}
.borders{
    border-bottom:3px solid #fcee21;
    width:100%;
}

#nav a {
  text-decoration:none !important;
  font-weight: bold;
  color:white;
}

#nav a.router-link-exact-active {
  color: #fcee21;
}

.cart {
    position: absolute;
    top: 75px;
    right: 50px;
  }

  .btn-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .leave-enter-active, .leave-leave-active {
    transition: all 1.2s;
  }
  .leave-enter, .leave-leave-to {
    opacity: 0;
    transform: translateX(-50%);
  }

  .appear-enter-active {
    animation: appear-animation .5s;
  }

  .appear-leave-active {
    animation: appear-animation .5s reverse;
  }

  @keyframes appear-animation {
    0% {
      transform: translateY(-50%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }

@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pragati+Narrow:wght@700&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');

</style>
